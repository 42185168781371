
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import CreateUi from "@/components/Core/Create/CreateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";

import { IPenetrantService } from "@/services";

@Component({
  components: {
    CreateUi,
    EquipmentBrandAutocomplete,
  },
})
export default class PenetrantCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get penetrantService() {
    return this.container.resolve<IPenetrantService>(S.PENETRANT_SERVICE);
  }

  get fluorescentChoices() {
    return [
      {
        value: true,
        text: "Fluorescent",
      },
      {
        value: false,
        text: "Non Fluorescent",
      },
    ];
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  equipmentType: string | null = null;
  equipmentBrandId: string | null = null;
  pvNumber: string | null = null;
  isFluorescent: boolean = false;

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1
    );
  }

  async create() {
    try {
      this.loading = true;
      const createdPenetrant = await this.penetrantService.create({
        serialNumber: this.serialNumber!,
        equipmentType: this.equipmentType!,
        equipmentBrandId: this.equipmentBrandId!,
        pvNumber: this.pvNumber!,
        isFluorescent: this.isFluorescent,
      });
      this.$emit("create", createdPenetrant);
    } finally {
      this.loading = false;
    }
  }
}
